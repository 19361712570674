import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/contact.css'
function createMarkup() {
    return {
        __html: `
    <div style="display: flex; justify-content: space-around; flex-wrap: wrap;"><a href="https://www.chilainc.org/" target="_blank" style="border: none;" rel="noopener noreferrer">
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Chila_Logo.jpg?v=1580168027');"></div>
    </a> 
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Premier_Plastics.png?v=1580167301');"></div>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <a href="https://www.azucenas.org/social-returns" target="_blank" style="border: none;" rel="noopener noreferrer">
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/azucenas-partner.jpg?v=1673396371');">
        </div>
    </a>
        </div>
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/ok.png?v=1673481799');"></div>
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Hybrid_Light_Logo.png?v=1580167301');"></div>
   
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Fortress_Gear.jpg?v=1580167301');"></div>
        <a href="https://www.snow.edu/" target="_blank" style="border: none;" rel="noopener noreferrer">
            <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Snow_College_Logo.jpg?v=1580167301');"></div>
         </a><a href="https://www.extremeautoservice.com" target="_blank" style="border: none;" rel="noopener noreferrer">
            <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/extreme_auto.png?v=1582773579');"></div>
        </a> 
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/King_Family_Foundation_2.PNG?v=1604369565');"></div>
       
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Dale_and_Karen_Lewis.jpg?v=1604337339');"></div>
      
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Dearden_Family_Logo_3.PNG?v=1580348562');"></div>
       
        <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Hosking_Family_2.jpg?v=1604369565');"></div>
       
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div style="margin-bottom: 40px; background-repeat: no-repeat; width: 250px; margin-left: 10px; margin-right: 10px; height: 180px; background-size: contain; background-position: center; background-image: url('https://cdn.shopify.com/s/files/1/0040/3786/9638/files/Jim_Holbrook.jpg?v=1604337284');"></div>
        </div>
    </div>`};
}
const Partners = () => (

    <Layout>
        <Seo title="Partners" />
        <div className="partners-page" style={{ display: 'flex', justifyContent: 'center' }}>

            <div style={{ maxWidth: '80%', marginTop: '50px' }}>
                <div>
                    <h1 style={{
                        textAlign: 'center', color: '#007676'
                    }}>Stones of Light <span id="accent">Partners</span></h1>
                    <hr />
                </div>
                <div dangerouslySetInnerHTML={createMarkup()} />;

            </div>

        </div>
    </Layout>
)

export default Partners
